module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Michelle & Tonye | Wedding Day","short_name":"Michelle & Tonye","icon":"static/images/icons/favicon.png","description":"Michelle & Tonye are getting married.","start_url":"/","background_color":"#633e24","theme_color":"#ffffff","display":"standalone","theme_color_in_head":false,"cache_busting_mode":"none","legacy":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxPluginMode":"GenerateSW","workboxConfig":{"maximumFileSizeToCacheInBytes":5000000,"globPatterns":["**/*.{js,jpeg,png,svg,html,css}"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
